<template lang="pug">
  .warning-body
    div.m-l-6 {{ $t("inventory_management.inventory_import.you_can_click_in_case") }}
    div.m-l-25 {{ $t("inventory_management.inventory_import.case_1") }}
    div.m-l-25 {{ $t("inventory_management.inventory_import.case_2") }}
    div.m-l-25 {{ $t("inventory_management.inventory_import.case_3") }}
    slot(name="import-filters")
    .buttons
      .button-container.justify-content-end
        button.btn.cancel(@click="$emit('close')") {{ $t("actions.cancel") }}
      .button-container.justify-content-start
        button.btn.import(
          :disabled="isImportDisabled"
          @click="$emit('start-import')"
        )
          | {{ $t("inventory_management.inventory_import.import") }}
        AppTooltip(
          :title="$t('inventory_management.inventory_import.dont_click_often')"
          icon="exclamation-triangle"
          :spin="importInventoryAvailableLoading"
        )
</template>

<script>
  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      isImportDisabled: {
        type: Boolean,
        default: false
      },
      importInventoryAvailableLoading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .warning-body
    width: 600px

    .buttons
      align-items: center
      background-color: $default-white
      bottom: 0
      border-top: 1px solid $border-element-color
      display: flex
      justify-content: space-between
      padding-top: 25px
      position: static

      .button-container
        align-items: center
        display: flex
        width: 49%

        .cancel
          border: 1px solid $default-purple
          color: $default-purple

        .import
          background-color: $default-red
          color: $default-white
</style>
